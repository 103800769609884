<template>
  <div>
    <div class="studetail_page">
      <van-nav-bar left-text="❮返回" @click-left="$router.replace('/mystu')"></van-nav-bar>
      <div class="studetail_con">
        <div class="info">
          <div class="scroll">
            <div class="stu">
              <div class="title">学员信息</div>
              <div class="ava">
                <img :src="info.face" alt />
              </div>
              <p>
                <span>姓名</span>
                <span>：</span>
                {{ info.name }}
              </p>
              <p>
                <span>性别</span>
                <span>：</span>
                {{ ["", "男", "女"][info.sex] }}
              </p>
              <p>
                <span>年龄</span>
                <span>：</span>
                {{ info.age }}
              </p>
              <p>
                <span>出生日期</span>
                <span>：</span>
                {{ $stampToTime(info.birth) }}
              </p>
              <p>
                <span>入训日期</span>
                <span>：</span>
                {{ $stampToTime(info.train_time) }}
              </p>
              <p>
                <span>班主任</span>
                <span>：</span>
                {{ info.adv_name }}
              </p>
            </div>
            <div class="tea">
              <div class="title">上课老师</div>
              <p v-for="item in info.tea_list" :key="item.lesson_id">
                <span>{{ item.less_name }}：</span>
                <b v-for="item1 in item.list" :key="item1.tea_id">
                  {{
                  item1.tea_name
                  }}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div class="course">
          <div class="title">课程历史</div>
          <div class="tabs">
            <div
              class="tab"
              v-for="item in info.less_list"
              :key="item.lesson_id"
              :class="{ active: activeTab == item.lesson_id }"
              @click="tabChange(item.lesson_id)"
            >{{ item.less_name }}</div>
          </div>
          <div class="table_box">
            <el-table :data="courseList.list" stripe>
              <el-table-column prop="id" label="序号" min-width="4%" align="center"></el-table-column>
              <el-table-column prop="tea_name" label="上课老师" min-width="7%" align="center"></el-table-column>
              <el-table-column prop="c_date" label="上课日期" min-width="12%" align="center">
                <template slot-scope="scope">
                  {{ $stampToTime(scope.row.c_date) }}
                  {{ scope.row.poin_time }}
                </template>
              </el-table-column>
              <el-table-column prop="train_num" label="训练项目" min-width="7%" align="center"></el-table-column>
              <el-table-column prop="ado_num" label="通过项目" min-width="7%" align="center"></el-table-column>
              <el-table-column prop="notes" label="操作" min-width="7%" align="center">
                <template slot-scope="scope">
                  <el-button @click="courseDetail(scope.row)" type="text" size="small">查看详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.page"
            :page-size="pagination.page_size"
            background
            layout="total, prev, pager, next"
            :total="pagination.totalCount"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/plugin/element";
export default {
  data() {
    return {
      info: {},
      activeTab: "",
      courseList: {},
      pagination: {
        page: 1,
        page_size: 10,
        totalCount: 0,
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let params = {
        uid: this.$route.query.uid,
      };
      const res = await this.$http.get("pad/pad/stu-info", { params: params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.info = res.data;
      if (res.data.less_list.length > 0) {
        this.activeTab = res.data.less_list[0].lesson_id;
        this.getCourseList();
      }
    },
    tabChange(id) {
      this.activeTab = id;
      this.pagination.page = 1;
      this.getCourseList();
    },
    async getCourseList() {
      let params = {
        uid: this.$route.query.uid,
        lesson_id: this.activeTab,
        page: this.pagination.page,
        page_size: this.pagination.page_size,
      };
      const res = await this.$http.get("pad/pad/les-sturec", {
        params: params,
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.courseList = res.data;
      this.pagination.totalCount = Number(res.data.page.totalCount);
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getCourseList();
    },
    courseDetail(row) {
      console.log(row);
      // 跳转到课程详情页面
      this.$router.push({
        name: "courseDetail",
        query: {
          sunhost_id: row.id,
          teach_type: row.teach_type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0;
}
.image {
  font-size: 0;
  line-height: 0;
}
img {
  width: 100%;
}
.van-nav-bar {
  background: rgba(255, 255, 255, 0.3);
  /deep/ .van-nav-bar__left,
  .van-nav-bar__right {
    font-size: 0.16rem;
    color: #333333;
    .van-nav-bar__text {
      color: #333;
    }
  }
  /deep/ .van-nav-bar__left {
    font-size: 0.14rem;
  }
  &::after {
    border: none;
  }
}
.scroll {
  height: 100%;
  overflow-y: scroll;
}
.studetail_page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #fbf7f7;
  min-height: 100vh;
  .studetail_con {
    padding: 0 0.2rem 0 0;
    margin-top: 0.16rem;
    height: calc(100vh - 0.62rem);
    display: flex;
    .info {
      width: 20%;
      background: #fff;
      border-radius: 0 0.08rem 0 0;
      overflow: hidden;
      .title {
        width: 87%;
        height: 0.3rem;
        background: #fbf7f7;
        border-radius: 0.04rem 0.04rem 0 0;
        margin: 0.1rem 0;
        font-size: 0.18rem;
        color: #290707;
        line-height: 0.3rem;
        padding-left: 0.2rem;
      }
      .ava {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        overflow: hidden;
        margin: 0.2rem auto;
        img {
          height: 100%;
        }
      }
      p {
        font-size: 0.14rem;
        color: #290707;
        padding: 0 0.2rem;
        line-height: 0.32rem;
        span {
          color: #666;
        }
        b {
          font-weight: normal;
          margin-right: 0.1rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .stu p span:first-child {
        display: inline-block;
        width: 0.6rem;
        text-align: justify;
        text-align-last: justify;
        line-height: 0;
        &:after {
          content: "";
          width: 100%;
          height: 0;
          display: inline-block;
          visibility: hidden;
        }
      }
    }
    .course {
      flex: 1;
      height: calc(100vh - 0.75rem);
      background: #fff;
      margin-left: 0.2rem;
      border-radius: 0.08rem;
      overflow: hidden;
      padding: 0.15rem 0.2rem;
      .title {
        font-size: 0.18rem;
        color: #333;
        font-weight: bold;
      }
      .tabs {
        margin: 0.1rem 0 0.2rem;
        border-bottom: 0.01rem solid #e2e2e2;
        .tab {
          display: inline-block;
          font-size: 0.16rem;
          color: #666;
          margin: 0 0.25rem -0.01rem 0;
          padding: 0 0.1rem 0.08rem;
          &.active {
            color: #f14947;
            border-bottom: 0.01rem solid #f14947;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .table_box {
        border-radius: 0.08rem 0.08rem 0 0;
        letter-spacing: 0;
        /deep/ table {
          &.el-table__header tr th {
            background: #ffeeee;
            font-size: 0.14rem;
            color: #333;
            font-weight: normal;
            border-left: 0.01rem solid #e8e8e8;
            &:first-child {
              border-left: 0.01rem solid #ffeeee;
            }
            &:last-child {
              border-right: 0.01rem solid #ffeeee;
            }
          }
          td,
          th.el-table__cell {
            border-color: #e8e8e8;
          }
          &.el-table__body td {
            border-left: 0.01rem solid #e8e8e8;
            &:last-child {
              border-right: 0.01rem solid #e8e8e8;
            }
          }
        }
        .el-table {
          border-radius: 0.08rem 0.08rem 0px 0px;
        }
        /deep/ .el-table__body-wrapper {
          max-height: 50vh;
          overflow-y: scroll;
          .cell {
            font-size: 0.13rem;
            padding: 0 0.09rem;
            .el-button {
              width: 0.8rem;
              height: 0.28rem;
              border-radius: 0.14rem;
              background: #ffebeb;
              color: #f14947;
            }
          }
        }
      }
      /deep/ .el-pagination {
        text-align: right;
        margin-top: 0.2rem;
        .el-pager li:not(.disabled).active {
          background: #f14947;
        }
      }
    }
  }
}
@media screen and (min-width: 640px) and (max-width: 820px) {
  .studetail_page .studetail_con .info {
    width: 25%;
  }
}
</style>